<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="목표년도"
            name="measureYear"
            type="year"
            default="today"
            v-model="searchParam.measureYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col1Items"
            itemText="codeName"
            itemValue="code"
            label="배출활동"
            name="col1"
            v-model="searchParam.col1">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="배출량 목표"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          :columnSetting="false"
          :isFullScreen="true"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" /> -->
              <c-btn v-if="editable" label="LBLSAVE" icon="save"/>
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '고체연료연소' },
        { code: '2', codeName: '기체연료연소' },
        { code: '2', codeName: '액체연료연소' },
      ],
      col2Items: [
        { code: '1', codeName: '공정시설' },
        { code: '2', codeName: '일반보일러실' },
        { code: '3', codeName: '기타' },
      ],
      grid: {
        merge: [
          { index: 0, colName: 'col5' },
          { index: 1, colName: 'col5' },
          { index: 2, colName: 'col5' },
          { index: 3, colName: 'col6' },
        ],
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col11',
            field: 'col11',
            label: '목표년도',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '배출활동',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '배출시설',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col0',
            field: 'col0',
            label: '연료별 목표배출량',
            align: 'center',
            child: [
              {
                name: 'col7',
                field: 'col7',
                label: '연료명',
                align: 'center',
                sortable: true,
              },
              {
                name: 'col8',
                field: 'col8',
                label: 'CO2',
                align: 'center',
                type: 'number',
                sortable: true,
              },
              {
                name: 'col9',
                field: 'col9',
                label: 'CH4',
                align: 'center',
                type: 'number',
                sortable: true,
              },
              {
                name: 'col10',
                field: 'col10',
                label: 'N2O',
                align: 'center',
                type: 'number',
                sortable: true,
              },
            ],
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '사업장1', 
          col5: '기체연료연소', 
          col6: '공정연소시설 #021', 
          col7: '나프타', 
          col8: '123', 
          col9: '0.8', 
          col10: '0.12', 
          col11: '2022',
        },
        {
          col1: '사업장1', 
          col5: '기체연료연소', 
          col6: '공정연소시설 #021', 
          col7: '중류', 
          col8: '21', 
          col9: '0.9', 
          col10: '0.11', 
          col11: '2022',
        },
        {
          col1: '사업장1', 
          col5: '기체연료연소', 
          col6: '공정연소시설 #033', 
          col7: 'LNG', 
          col8: '120', 
          col9: '0.35', 
          col10: '0.7', 
          col11: '2022',
        },
      ];
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./baseinfo/dischargeFacDetail.vue"}`);
      this.popupOptions.title = '온실가스 배출시설 상세';
      this.popupOptions.param = {
        psiDocumentId: result,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
